import React from 'react';
import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';

export const PublicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${themeMain.colors.gray[600]};
`;

const PublicLayout = ({ children }) => (
  <PublicWrapper>{children}</PublicWrapper>
);

export default PublicLayout;
