import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { useQuery } from "react-query";

import { getAllVaccinesShots, getPDFInfo } from 'api/playerInformation';
import { Button } from 'components/atoms/Button';


Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: '10px',
  },
  body: {
    padding: '25px',
  },
  title: {
    margin: '0 0 10px',
    textAlign: 'center',
    backgroundColor: '#e4e4e4',
    fontFamily: 'Oswald',
    fontSize: 14,
  },
  separator: {
    backgroundColor: '#e4e4e4',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  section: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexFlow: 'column wrap',
    margin: '0 0 10px',
  },
  text: {
    margin: '0 0 10px',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Oswald',
    textAlign: 'left',
  },
  label: {
    margin: 0,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Oswald',
    textAlign: 'left',
  },
  small: {
    fontSize: 13,
    color: '#808080',
    textAlign: 'left',
  },
  photos: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: '0 0 10px',
  },
  image: {
    display: 'inline-block',
    height: 300,
    width: 300,
    objectFit: 'contain',
    margin: '0 20px 20px 0',
  },
});

const Doc1 = ({ params, done, vaccinesShots }) => {
  const [data, setData] = useState(null);

  const _getPresentedInformation = (elem) => {
    switch (elem.category_type.name) {
      case 'results':
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Problem', value: elem.problem?.name },
          { id: 2, type: 'text', label: 'Category', value: elem.information_results?.category_type?.name },
          { id: 3, type: 'text', label: 'Date', value: elem.information_results?.date },
          { id: 4, type: 'text', label: 'Indication', value: elem.information_results?.indication },
          { id: 5, type: 'text', label: 'Tests', value: elem.information_results?.tests },
          { id: 6, type: 'text', label: 'Outcome', value: elem.information_results?.outcome },
          { id: 7, type: 'file', label: 'Photo(s)', value: elem?.information_results?.photos },
          {
            id: 12,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ];

      case "consultations":
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Clinician Name', value: elem.consult.clinicianName },
          { id: 2, type: 'text', label: 'Location', value: elem.consult.location },
          { id: 3, type: 'text', label: 'Registration Number', value: elem.consult.registrationNumber },
          { id: 4, type: 'text', label: 'Problem', value: elem.problem?.name },
          { id: 5, type: 'text', label: 'History', value: elem.consult.history },
          {
            id: 6,
            type: 'list',
            label: 'Investigation list',
            value: elem.consult.investigations.map(el => el.name)
          },
          { id: 7, type: 'text', label: 'Exam', value: elem.consult.exam },
          { id: 8, type: 'text', label: 'Diagnosis', value: elem.consult.diagnosis.name },
          { id: 9, type: 'list', label: 'Treatment', value: elem.consult.plan?.plans.map(el => el.name) },
          { id: 10, type: 'text', label: 'Treatment details', value: elem.consult.details },
          {
            id: 11,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ]

      case "details":
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Category', value: elem.information_problem?.name },
          {
            id: 2,
            type: 'text',
            label: 'From',
            value: new Date(elem.information_problem?.from).toString().split('(')[0]
          },
          {
            id: 3,
            type: 'text',
            label: 'Until',
            value: new Date(elem.information_problem?.until).toString().split('(')[0]
          },
          { id: 4, type: 'text', label: 'Notes', value: elem.information_problem?.notes },
          { id: 5, type: 'file', label: 'Photo(s)', value: elem.information_problem?.photos },
          {
            id: 6,
            type: 'text',
            label: 'Problem status',
            value: elem.information_problem.problem_status.name
          },
          { id: 7, type: 'text', label: 'MSK', value: elem.information_problem.msk.name },
          {
            id: 8,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ]

      case "medications":
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Problem', value: elem.problem?.name },
          { id: 2, type: 'text', label: 'Category', value: elem.medications.category_type.name },
          {
            id: 3,
            type: 'text',
            label: 'Date',
            value: new Date(elem.medications.date).toString().split('(')[0]
          },
          { id: 4, type: 'text', label: 'Name', value: elem.medications.name },
          { id: 5, type: 'text', label: 'Form', value: elem.medications?.form },
          { id: 6, type: 'text', label: 'Dose', value: elem.medications?.dose },
          { id: 7, type: 'text', label: 'Times', value: elem.medications?.times },
          { id: 8, type: 'text', label: 'Indication', value: elem.medications?.reason },
          { id: 9, type: 'text', label: 'Medication', value: elem.medications?.medication },
          { id: 10, type: 'text', label: 'Reaction', value: elem.medications?.reaction },
          {
            id: 11,
            type: 'list',
            label: 'Injection',
            value: elem.medications.vaccinesIdsList?.map(vId => vaccinesShots.find(shot => shot.id === +vId)?.name) || []
          },
          {
            id: 12,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ]

      case "letters":
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Problem', value: elem.problem?.name },
          { id: 2, type: 'text', label: 'Name', value: elem.information_letters?.name },
          { id: 3, type: 'text', label: 'Date', value: elem.information_letters?.date },
          { id: 4, type: 'text', label: 'Indication', value: elem.information_letters?.indication },
          { id: 5, type: 'text', label: 'Outcome', value: elem.information_letters?.outcome },
          { id: 6, type: 'file', label: 'File upload', value: elem.information_letters?.files },
          {
            id: 7,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ]

      case "treatments":
        return [
          { id: 0, type: 'text', label: 'Information category', value: elem.category_type.name },
          { id: 1, type: 'text', label: 'Problem', value: elem.problem?.name },
          { id: 2, type: 'text', label: 'Category', value: elem.treatments.category_type.name },
          { id: 3, type: 'text', label: 'Date', value: elem.treatments?.date },
          { id: 4, type: 'text', label: 'Notes', value: elem.treatments?.details },
          { id: 5, type: 'text', label: 'Body part', value: elem.treatments?.body_part },
          { id: 6, type: 'text', label: 'Drug', value: elem.treatments?.drug },
          { id: 7, type: 'text', label: 'Dose', value: elem.treatments?.dose },
          {
            id: 8,
            type: 'text',
            label: 'Information created at',
            value: new Date(elem.created_at).toString().split('(')[0]
          },
        ]

      default:
        return null
    }
  }

  const _renderInformationElement = (el) => {
    switch (el.type) {
      case "text":
        if (!el.value) return null
        return (
          <Text key={el.id} style={styles.text}>
            {el.label}:{' '}
            <Text style={styles.small}>{el.value ?? 'N/A'}</Text>
          </Text>
        )

      case "list":
        if (!el?.value?.length) return null
        return (
          <View key={el.id}  style={styles.section}>
            <Text style={styles.label}>
              {el.label}:{' '}
            </Text>
            {el.value?.map((v, index) => <Text key={index} style={styles.small}>{v}</Text>)}
          </View>
        )

      case "file":
        // Temporarily removing to go-live without breaking images.
        return null
        if (!el.value?.length) return null;
        return (
          <View key={el.id} >
            <Text style={styles.text}>Photo(s):</Text>
            {el.value?.map(file => (
              <Image
                key={file?.id}
                src={file?.publicUrl}
                style={styles.image}
              />
            ))}
          </View>
        )

      default:
        return null
    }
  }

  useEffect(() => {
    const foo = async () => {
      const playerInfo = await getPDFInfo(params);
      setData(playerInfo);

      setTimeout(() => {
        done();
      }, 1000);
    };
    foo();
  }, []);

  if (data === null) {
    return null;
  }

  const { player, information, problems } = data;

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        {/* <Link
          style={styles.title}
          src='https://es.wikipedia.org/wiki/Lorem_ipsum'
        >
          Lorem Ipsum
        </Link> */}
        <Text style={styles.title}>Player main information</Text>
        <View style={styles.body}>
          <View style={styles.row}>
            <Text style={styles.text}>
              Name: <Text style={styles.small}>{player.user.first_name}</Text>
            </Text>
            <Text style={styles.text}>
              Lastname:{' '}
              <Text style={styles.small}>{player.user.last_name}</Text>
            </Text>
            <Text style={styles.text}>
              Date of birth: <Text style={styles.small}>{player.birthday}</Text>
            </Text>
            <Text style={styles.text}>
              Gender: <Text style={styles.small}>{player.gender.name}</Text>
            </Text>
            <Text style={styles.text}>
              Team: <Text style={styles.small}>{player.team.name}</Text>
            </Text>
            <Text style={styles.text}>
              Status: <Text style={styles.small}>{player.status.name}</Text>
            </Text>
            <Text style={styles.text}>
              NHS number: <Text style={styles.small}>{player.nhs_number}</Text>
            </Text>
            <Text style={styles.text}>
              Email: <Text style={styles.small}>{player.user.email}</Text>
            </Text>
            <Text style={styles.text}>
              Phone number:{' '}
              <Text style={styles.small}>{player.user.phone_number}</Text>
            </Text>
            <Text style={styles.text}>
              GP surgery: <Text style={styles.small}>{player.gp_surgery}</Text>
            </Text>
            <Text style={styles.text}>
              Address 1: <Text style={styles.small}>{player.address_1}</Text>
            </Text>
            <Text style={styles.text}>
              Address 2: <Text style={styles.small}>{player.address_2}</Text>
            </Text>
            <Text style={styles.text}>
              Next of kin:{' '}
              <Text style={styles.small}>{player.next_of_kin}</Text>
            </Text>
            <Text style={styles.text}>
              Nationality:{' '}
              <Text style={styles.small}>{player.nationality.name}</Text>
            </Text>
            <Text style={styles.text}>
              City: <Text style={styles.small}>{player.city}</Text>
            </Text>
            <Text style={styles.text}>
              Postcode: <Text style={styles.small}>{player.postcode}</Text>
            </Text>
          </View>
        </View>
      </Page>

      {!!problems.length && (
        <Page size='A4' style={styles.page}>
          <Text style={styles.title}>Player problems</Text>
          <View style={styles.body}>
            {problems.map((problem, index) => {
              return (
                <View style={styles.row} key={index}>
                  <Text style={styles.separator}>{index + 1}</Text>
                  <Text style={styles.text}>
                    Problem name:{' '}
                    <Text style={styles.small}>{problem?.name ?? 'N/A'}</Text>
                  </Text>
                  <Text style={styles.text}>
                    Problem category:{' '}
                    <Text style={styles.small}>{problem?.category?.name ?? 'N/A'}</Text>
                  </Text>
                  <Text style={styles.text}>
                    Problem category description:{' '}
                    <Text style={styles.small}>
                      {problem?.category?.description ?? 'N/A'}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    Diagnosis code:{' '}
                    <Text style={styles.small}>
                      {problem?.category?.diagnosis_code?.code ?? 'N/A'}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    Diagnosis name:{' '}
                    <Text style={styles.small}>
                      {problem?.category?.diagnosis_code?.name ?? 'N/A'}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    Added by:{' '}
                    <Text style={styles.small}>{problem.added_by}</Text>
                  </Text>
                  <Text style={styles.text}>
                    Created at:{' '}
                    <Text style={styles.small}>
                      {new Date(problem.created_at).toString().split('(')[0]}
                    </Text>
                  </Text>
                </View>
              );
            })}
          </View>
        </Page>
      )}

      {!!information.length && (
        <Page size='A4' style={styles.page}>
          <Text style={styles.title}>Player information</Text>
          <View style={styles.body}>
            {information.map((elem, index) => {
              return (
                <View style={styles.row} key={index + elem.id}>
                  <Text style={styles.separator}>{index + 1}</Text>
                  {_getPresentedInformation(elem).map(_renderInformationElement)}
                </View>
              )
            })}
          </View>
        </Page>
      )}
    </Document>
  );
};

export const PdfDocument1 = ({ params }) => {
  const [isClicked, setClicked] = useState(false);
  const [isFinished, setFinished] = useState(false);
  const { data: vaccinesShots } = useQuery('getVaccines', getAllVaccinesShots)

  useEffect(() => {
    if (isFinished) {
      // TODO: Once images are re-added back in, look into a nicer alternative
      // Weird thing to add in I know, but for some reason it doesn't trigger correctly unless you click twice
      // I think it's because the conditional render isn't in the DOM by the time this is ran,
      // but briefly taking it out the callstack resolves this issue.
      setTimeout(() => {
        const elem = document.getElementById('some');
        elem.children[0].click();
        setClicked(false)
        setFinished(false)
      })
    }
  }, [isFinished]);
  return (
    <>
      <Button variant={'secondary'} onClick={() => setClicked(true)}>
        Export Player
      </Button>
      {isClicked ? (
        <div id={'some'}>
          <PDFDownloadLink
            document={<Doc1 params={params} vaccinesShots={vaccinesShots} done={() => setFinished(true)}/>}
            fileName='fee_acceptance.pdf'
          />
        </div>
      ) : null}
    </>
  );
};
