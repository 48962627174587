import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Select from "react-select";
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { Typography } from '../../../../atoms/Typography';
import { useHistory } from 'react-router-dom';
import {
  FormItem,
  InputError,
  Fieldset,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { getResultsCategories } from 'api/playerInformation';
import { useAddInfoResultExternal } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import { REQUIRED_FIELD } from "../../../../../constants/massages";

Modal.setAppElement('#root');

export const ResultsModule = ({ params }) => {

  const maxDate = new Date().toISOString().split('T')[0];

  console.log('externalReferralId: ', params.externalReferralId);

  const methods = useForm({ mode: "all",
    defaultValues: {
      date: maxDate,
    } });

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = methods;

  // Receiving entity "medicationCategories"
  const { data: resultsCategories = [] } = useQuery(
    'resultsCategories',
    getResultsCategories
  );

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as an 3rd argument
  const categoryOptions = [];
  for (let i = 0; i < resultsCategories.length; ++i) {
    categoryOptions.push({
      value: resultsCategories[i],
      label: resultsCategories[i].name,
    });
  }

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isAddLoading } = useAddInfoResultExternal();

  const watchPhotos = watch('photos');

  const [formPhotos, setFormPhotos] = useState([]);

  useEffect(() => {
    setFormPhotos(getValues('photos'));
  }, [watchPhotos]);

  const onDelete = (index) => {
    setFormPhotos(
      Array.from(formPhotos).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    formData.append('categoryTypeId', data.resultsCategory.value.id);
    formData.append('date', data.date);
    formData.append('outcome', data.outcome);
    formData.append('tests', data.tests);

    if (data.indication) {
      formData.append('indication', data.indication);
    }

    if (data.photos) {
      for (const key of data.photos) {
        formData.append('file', key);
      }
    }

    try {
      await mutateAsync({
        externalReferralId: params.externalReferralId,
        formData,
      });
      push(`/referrals/${params.externalReferralId}/info`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Fieldset>
          <FormItem>
            <Controller
              name='resultsCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='resultsCategory'>Category</label>
                  <Select
                    {...field}
                    inputId='resultsCategory'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['resultsCategory'] ? 'error' : ''
                    }`}
                    options={categoryOptions}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='resultsCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='date'
              max={maxDate}
              {...register('date', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Date'
              error={errors.name}
              className={`${errors['date'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='date' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='indication'
              name='indication'
              type='text'
              variant='textarea'
              {...register('indication')}
              error={errors.name}
              placeholder='Enter Here'
              label='Indication'
              className={`${errors['indication'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='indication' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='tests'
              name='tests'
              type='text'
              variant='textarea'
              {...register('tests', { required: REQUIRED_FIELD })}
              placeholder='Enter Here'
              label='Tests'
              error={errors.name}
              className={`${errors['tests'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='tests' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='outcome'
              name='outcome'
              type='text'
              variant='textarea'
              {...register('outcome', { required: 'This is required' })}
              placeholder='Enter Here'
              label='Outcome'
              error={errors.name}
              className={`${errors['outcome'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='outcome' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='photos'
              name='photos'
              type='file'
              multiple
              accept='image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              {...register('photos')}
              placeholder='Enter Here'
              label='Photos'
              error={errors.name}
              className={`${errors['photos'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='photos' as={InputError} />
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return (
                  <div key={i + index}>
                    {i.name}
                    <Button
                      aria-label='Delete'
                      icon
                      type='button'
                      onClick={() => onDelete(index)}
                    >
                      <Icon name='remove' />
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
          </FormItem>
        </Fieldset>
        <Button type='button' onClick={onPreSubmit}>
          <Icon name='check' />
          Submit Information
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          {submitMessage?.length > 0 ? (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Something went wrong
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  {submitMessage}
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModalClear} variant={'secondary'}>
                  Try again
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Are you sure?
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  Do you really want to add this information?
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModal} variant={'secondary'}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disabled={isAddLoading}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </Modal>
      </form>
    </FormProvider>
  );
};
