import axiosWrapper from './axiosInterceptor';

export const registration = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/auth/registration',
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const login = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/auth/log-in',
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const tflogin = async ({ token, ...data }) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/auth/log-in-confirm',
      responseType: 'json',
      data: data,
      withCredentials: true,
      headers: {
        Confirm: token,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const passwordReset = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PATCH',
      url: '/auth/password-reset',
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const createPassword = async ({ token, ...data }) => {
  try {
    const response = await axiosWrapper({
      method: 'PATCH',
      url: '/auth/password-reset-confirm',
      responseType: 'json',
      data: data,
      withCredentials: true,
      headers: {
        'Reset-Password-Confirm': token,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const logout = async () => {
  try {
    const response = await axiosWrapper({
      method: 'PATCH',
      url: `/auth/log-out`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const invite = async ({ token }) => {
  try {
    const response = await axiosWrapper({
      method: 'PATCH',
      url: '/auth/invitation-confirm',
      responseType: 'json',
      withCredentials: true,
      headers: {
        'Mail-Invitation-Confirm': token,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
