import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Registration from '../components/organisms/Registration/Registration';
import Login from '../components/organisms/Login/Login';
import PublicRoute from '../fragments/PublicRoute';
import ConsultRoute from 'fragments/ConsultRoute';
import PrivateLayout from '../fragments/layouts/PrivateLayout';
import PrivateRoute from '../fragments/PrivateRoute';
import Navigator from '../fragments/Navigator';
import ProviderTheme from '../theme/providerTheme';
import { Teams } from '../components/pages/Teams';
import { SearchContext, UserContext } from '../context';
import { useUser } from '../hooks/useUser';
import { Players } from '../components/pages/Players';
import { External } from '../components/pages/External';
import { ForgotPassword } from 'components/organisms/Login/ForgotPassword';
import { TFAuth } from 'components/organisms/Login/TFAuth';
import { Invitation } from 'components/organisms/Login/Invitation';
import { Password } from 'components/organisms/Login/Password';
import { Configuration } from 'components/pages/Configuration';
import { Account } from 'components/pages/Account';
import { useSearch } from 'hooks/useSearch';
import { SearchResults } from 'components/pages/SearchResults';
import { Timeline } from 'components/pages/Timeline';
import ConsultLayout from 'fragments/layouts/ConsultLayout';
import { AddPublicConsult } from 'components/pages/AddPublicConsult';
import { MobileInvitation } from '../components/pages/Mobile/Invitation'
import { MobileResetPassword } from "../components/pages/Mobile/MobileResetPassword";
import { MobileTFA } from "../components/pages/Mobile/MobileTFA";
import { ErrorNotFound } from "../components/pages/Errors/NotFound";
import { ExternalUsersLandingPage } from "../components/pages/ExternalUsersLandingPage";

import { Print } from "../components/pages/Print";
import PrivatePrintLayout from "../fragments/layouts/PrivatePrintLayout";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={useUser()}>
        <SearchContext.Provider value={useSearch()}>
          <ProviderTheme>
            <Router>
              <Switch>
                <PublicRoute path={'/registration'}>
                  <Registration />
                </PublicRoute>
                <PublicRoute path={'/login'}>
                  <Login />
                </PublicRoute>
                <PublicRoute
                  path={'/auth/forgot-password'}
                >
                  <ForgotPassword />
                </PublicRoute>
                <PublicRoute
                  path={'/auth/confirm/:token?'}
                >
                  <TFAuth />
                </PublicRoute>
                <PublicRoute
                  path={'/auth/invitation/:token?'}
                >
                  <Invitation />
                </PublicRoute>
                <PublicRoute
                  path={'/auth/password-reset/:token?'}
                >
                  <Password />
                </PublicRoute>
                <PublicRoute exact path={'/mobile/auth/invitation/:token'}>
                  <MobileInvitation />
                </PublicRoute>
                <PublicRoute exact path={'/mobile/auth/confirm/:token'}>
                  <MobileTFA />
                </PublicRoute>
                <PublicRoute exact path={'/mobile/auth/password-reset/:token'}>
                  <MobileResetPassword />
                </PublicRoute>
                <ConsultRoute
                  path={
                    '/teams/:teamId/players/:playerId/add-information/public-consult/:token?'
                  }
                  layout={ConsultLayout}
                >
                  <AddPublicConsult />
                </ConsultRoute>
                <PrivateRoute path={'/teams'} layout={PrivateLayout}>
                  <Teams />
                </PrivateRoute>
                <PrivateRoute path={'/players'} layout={PrivateLayout}>
                  <Players />
                </PrivateRoute>
                <PrivateRoute path={'/external'} layout={PrivateLayout}>
                  <External />
                </PrivateRoute>
                <PrivateRoute path={'/timeline'} layout={PrivateLayout}>
                  <Timeline />
                </PrivateRoute>
                <PrivateRoute path={'/configuration'} layout={PrivateLayout}>
                  <Configuration />
                </PrivateRoute>
                <PrivateRoute path={'/account'} layout={PrivateLayout}>
                  <Account />
                </PrivateRoute>
                <PrivateRoute path={'/search-results'} layout={PrivateLayout}>
                  <SearchResults />
                </PrivateRoute>
                <PrivateRoute path={'/print'} layout={PrivatePrintLayout}>
                  <Print />
                </PrivateRoute>
                <PrivateRoute path={'/referrals'} layout={PrivateLayout}>
                  <ExternalUsersLandingPage />
                </PrivateRoute>
                <PrivateRoute path={'/archived'} layout={PrivateLayout}>
                  <ExternalUsersLandingPage />
                </PrivateRoute>
                <Route exact path={'/'}>
                  <Navigator />
                </Route>
                <Route path={'*'}>
                  <ErrorNotFound />
                </Route>
              </Switch>
            </Router>
            {/*<ReactQueryDevtools initialIsOpen />*/}
          </ProviderTheme>
        </SearchContext.Provider>
      </UserContext.Provider>
    </QueryClientProvider>
  );
}
