import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import Select from "react-select";
import Downshift from 'downshift';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
  PhotosWrapper,
  CheckboxGroup,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { useAddProblem } from './hooks/useAddProblem';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { useDebounce } from './hooks/useDebounce';
import { getAllProblemCategories } from 'api/problems';
import { getProblemCategoryTypes } from 'api/playerInformation';
import { DownshiftList } from './DownshiftList';
import { trimFormData } from "../../../utils/trimFormData";
import { getPrivacyTypes } from "../../../api/privacyTypes";
import { Checkbox } from "../../atoms/Checkbox";
import { formatFormDate } from "../../../utils/formatFormDate";
//import { EnumInformationDetailsMsk } from "../../../constants/informationDetails";

Modal.setAppElement('#root');

export const AddProblem = () => {
  let { teamId, playerId } = useParams();

  const maxDate = new Date().toISOString().split('T')[0];

  const {
    register,
    unregister,
    handleSubmit,
    control,
    trigger,
    watch,
    resetField,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const selectedSubCategory = watch('category')

  // Receiving entity "informationCategories"
  const { data: informationCategories = [] } = useQuery(
    'problemCategoryTypes',
    getProblemCategoryTypes
  );

  const categories = informationCategories.reduce((accumulator, item) => {
    if (item.parent_id !== 0) {
      return accumulator?.map(i => {
        if (i.id === item.parent_id) {
          return { ...i, subCategories: [ ...(i.subCategories || []), item ]}
        }
        return i
      })
    }
    return [...accumulator, item]
  }, [])

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as an 3rd argument
  const categoryOptions = categories.map(category => ({
    value: category,
    label: category.name,
  }))

  // Receiving entity "teams"
  const { data: problemCategories } = useQuery(
    'allProblemCategories',
    getAllProblemCategories
  );

    // Receiving entity "privacyTypesData" by invoking "getPrivacyTypes" hook
    const { data: privacyTypesData = [] } = useQuery('privacyType', getPrivacyTypes);
    const privacyTypesOptions = [];
    for (let i = 0; i < privacyTypesData.length; i++) {
        privacyTypesOptions.push({
            value: privacyTypesData[i],
            label: privacyTypesData[i].name,
        });
    }

  const problemOptions = problemCategories?.problemTypes.map((item) => {
    return {
      value: item?.id,
      label: item.name,
    };
  });

  const illnessOptions = problemCategories?.illnessTypes.map((item) => {
    return {
      value: item?.id,
      label: item.name,
    };
  });

  const specialityOptions = problemCategories?.illnessSelectBoxList.map(
    (item) => {
      return {
        value: item?.id,
        label: item.name,
      };
    }
  );

  const bodyPartOptions = problemCategories?.injuryTypes.map((item) => {
    return {
      value: item?.id,
      label: item.name,
    };
  });

  const sideOptions = problemCategories?.injurySelectBoxList.map((item) => {
    return {
      value: item?.id,
      label: item.name,
    };
  });

  const subCategoryOptions = selectedSubCategory?.value?.subCategories?.map(item => ({ label: item.name, value: item })) || []

  const sideOptionsForHead = [sideOptions?.find((item) => item.value === 3)]

  const [formPhotos, setFormPhotos] = useState([]);

  const { mutateAsync, isLoading: isLoadingButton } = useAddProblem();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

    const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);
    // !!!
    console.log('data: ', JSON.stringify(data));

    const formData = new FormData();
    if (data.problemName) {
      formData.append('name', data.problemName);
    }
    if (data.problemDescription) {
      formData.append('description', data.problemDescription);
    }

    formData.append('categoryId', data.categoryId);
    if (data.problemType?.value) {
      formData.append('problemTypeId', data.problemType?.value);
    }

      if (data.privacyType?.value) {
          formData.append('privacyTypeId', parseInt(data.privacyType?.value?.id || data.privacyType?.value));
      }

    if (data.illnessType?.value) {
      formData.append('illnessTypeId', data.illnessType?.value);
    }
    if (data.speciality?.value) {
      formData.append('illnessSelectBoxId', data.speciality?.value);
    }
    if (data.bodyPart?.value) {
      formData.append('injuryTypeId', data.bodyPart?.value);
    }
    if (data.side?.value) {
      formData.append('injurySelectBoxId', data.side?.value);
    }

    formData.append('categoryTypeId', data.subCategory?.value?.id || data.category?.value?.id);
    formData.append('from', data.from);
    if (data.until) {
      formData.append('until', data.until);
    }

    formData.append('problemStatusId', data.major);
    //formData.append('mskId', data.MSK);
    formData.append('notes', data.notes);

    if (data.photos) {
      for (const key of data.photos) {
        formData.append('photos', key);
      }
    }

      // console.log('privacyTypeId: ', formData.get('privacyTypeId'));

/*    console.log(formData.get('name'));
    console.log(formData.get('description'));
    console.log(formData.get('categoryId'));
    console.log('problemTypeId: ' + formData.get('problemTypeId'));
    console.log(formData.get('illnessTypeId'));
    console.log(formData.get('illnessSelectBoxId'));
    console.log(formData.get('injuryTypeId'));
    console.log(formData.get('injurySelectBoxId'));
    console.log(formData.get('categoryTypeId'));
    console.log(formData.get('from'));
    console.log(formData.get('until'));
    console.log(formData.get('problemStatusId'));
    //console.log(formData.get('mskId'));
    console.log(formData.get('notes'));
    console.log(formData.get('photos'));*/


    try {
      await mutateAsync({
        teamId,
        playerId,
        formData,
      });
      push(`/teams/${teamId}/players/${playerId}/problems`);
    } catch (error) {
      console.log('error', error);
      //console.log(JSON.stringify(formData));
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    const result = await trigger();

    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  const [, setDownShiftValue, items] = useDebounce({
    delay: 1000,
    clearErrors,
    inputName: 'categoryId',
  });

  const watchProblemType = watch('problemType');

  const watchBodyPart = watch('bodyPart');

  const resetDownshiftValue = async () => {
    setValue('categoryId', '');
    await trigger();
  };

  const onDownShiftSelected = (selected) => {
    if (selected) {
      setDownShiftValue(selected.id);
      setValue('categoryId', selected.id);
    }
  };

  // In case we need to unregister some fields
  useEffect(() => {
    if (watchProblemType?.value) {
      (watchProblemType.label !== 'illness' &&
        unregister(['illnessType', 'speciality'])) ||
        (watchProblemType.label !== 'injury' &&
          unregister(['bodyPart', 'side']));
    }
  }, [watchProblemType?.value]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add Problem
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/players/${playerId}/problems`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Controller
              control={control}
              name='categoryId'
              render={() => (
                <Downshift
                  itemToString={(item) => (item ? `${item.name} [${item.diagnosis_code.name}]` : '')}
                  onInputValueChange={(typed) => setDownShiftValue(typed)}
                  onChange={(selection) => onDownShiftSelected(selection)}
                  //inputValue={downShiftValue}
                >
                  {({
                      getInputProps,
                      getItemProps,
                      getLabelProps,
                      getMenuProps,
                      clearSelection,
                      isOpen,
                      selectedItem,
                    }) => (
                    <div>
                      <label {...getLabelProps()}>Search against name (*required for coding)</label>
                      <Input
                        {...getInputProps()}
                        placeholder='Enter here (minimum 2 characters)'
                        className={`react-select ${
                          errors['categoryId'] ? 'error' : ''
                        }`}
                      >
                        {selectedItem ? (
                          <div
                            className='css-tlfecz-indicatorContainer downshift-close'
                            aria-hidden='true'
                            onClick={() => {
                              clearSelection();
                              resetDownshiftValue();
                            }}
                          >
                            <svg
                              height='20'
                              width='20'
                              viewBox='0 0 20 20'
                              aria-hidden='true'
                              focusable='false'
                              className='css-tj5bde-Svg'
                            >
                              <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z' />
                            </svg>
                          </div>
                        ) : null}
                      </Input>
                      <DownshiftList {...getMenuProps()}>
                        {isOpen && items
                          ? items
                            // .filter(
                            //   (item) =>
                            //     !inputValue || item.value.includes(inputValue)
                            // )
                            .map((item, index) => (
                              <li
                                {...getItemProps({
                                  key: item.id,
                                  index,
                                  item,
                                  style: {
                                    fontWeight:
                                      selectedItem === item
                                        ? 'bold'
                                        : 'normal',
                                  },
                                })}
                              >
                                {`${item.name} [${item.diagnosis_code.name}]`}
                              </li>
                            ))
                          : null}
                      </DownshiftList>
                    </div>
                  )}
                </Downshift>
              )}
            />
            <ErrorMessage errors={errors} name='categoryId' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='problemName'
              name='problemName'
              type='text'
              {...register('problemName', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Diagnosis – free text (*required for app)'
              error={errors.name}
              className={`${errors['problemName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='problemName' as={InputError} />
          </FormItem>
          <FormItem>
              <Controller
                  name='privacyType'
                  control={control}
                  rules={{  }}
                  render={({ field }) => (
                      <>
                          <label htmlFor='privacyType'>Privacy Type</label>
                          <AsyncPaginate
                              {...field}
                              inputId='privacyType'
                              isClearable
                              placeholder='All medics'
                              className={`react-select ${
                                  errors['privacyType'] ? 'error' : ''
                              }`}
                              loadOptions={(search, prevOptions) =>
                                  loadOptions(search, prevOptions, privacyTypesOptions)
                              }
                          />
                      </>
                  )}
              />
              <ErrorMessage errors={errors} name='privacyType' as={InputError} />
          </FormItem>
          <FormItem>
            <Controller
              name='problemType'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='problemType'>Problem Type</label>
                  <AsyncPaginate
                    {...field}
                    inputId='problemType'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['problemType'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, problemOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage errors={errors} name='problemType' as={InputError} />
          </FormItem>
          {watchProblemType && (
            <>
              {watchProblemType.label === 'medical' && (
                <>
                  <FormItem>
                    <Controller
                      name='illnessType'
                      control={control}
                      rules={{ required: 'This is required' }}
                      render={({ field }, ref) => (
                        <>
                          <label htmlFor='illnessType'>Medical type</label>
                          <AsyncPaginate
                            {...field}
                            inputId='illnessType'
                            ref={ref}
                            isClearable
                            placeholder={'Your information'}
                            className={`react-select ${
                              errors['illnessType'] ? 'error' : ''
                            }`}
                            loadOptions={(search, prevOptions) =>
                              loadOptions(search, prevOptions, illnessOptions)
                            }
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='illnessType'
                      as={InputError}
                    />
                  </FormItem>
                  <FormItem>
                    <Controller
                      name='speciality'
                      control={control}
                      rules={{ required: 'This is required' }}
                      render={({ field }, ref) => (
                        <>
                          <label htmlFor='speciality'>Speciality</label>
                          <AsyncPaginate
                            {...field}
                            inputId='speciality'
                            ref={ref}
                            isClearable
                            placeholder={'Your information'}
                            className={`react-select ${
                              errors['speciality'] ? 'error' : ''
                            }`}
                            loadOptions={(search, prevOptions) =>
                              loadOptions(
                                search,
                                prevOptions,
                                specialityOptions
                              )
                            }
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='speciality'
                      as={InputError}
                    />
                  </FormItem>
                </>
              )}
              {watchProblemType.label === 'injury' && (
                <>
                  <FormItem>
                    <Controller
                      name='bodyPart'
                      control={control}
                      rules={{ required: 'This is required' }}
                      render={({ field }, ref) => (
                        <>
                          <label htmlFor='bodyPart'>Body Part</label>
                          <AsyncPaginate
                            {...field}
                            inputId='bodyPart'
                            ref={ref}
                            isClearable
                            placeholder={'Your information'}
                            className={`react-select ${
                              errors['bodyPart'] ? 'error' : ''
                            }`}
                            loadOptions={(search, prevOptions) =>
                              loadOptions(search, prevOptions, bodyPartOptions)
                            }
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='bodyPart'
                      as={InputError}
                    />
                  </FormItem>
                  {watchBodyPart && (
                    <>
                      {watchBodyPart.value === 1 && (
                        <FormItem>
                          <Controller
                            name='side'
                            control={control}
                            rules={{ required: 'This is required' }}
                            render={({ field }, ref) => (
                              <>
                                <label htmlFor='side'>Side</label>
                                <AsyncPaginate
                                  {...field}
                                  inputId='side'
                                  ref={ref}
                                  isClearable
                                  placeholder={'Your information'}
                                  className={`react-select ${
                                    errors['side'] ? 'error' : ''
                                  }`}
                                  loadOptions={(search, prevOptions) =>
                                    loadOptions(
                                      search,
                                      prevOptions,
                                      sideOptionsForHead
                                    )
                                  }
                                />
                              </>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name='side'
                            as={InputError}
                          />
                        </FormItem>
                      )}
                      {watchBodyPart.value !== 1 && (
                        <FormItem>
                          <Controller
                            name='side'
                            control={control}
                            rules={{ required: 'This is required' }}
                            render={({ field }, ref) => (
                              <>
                                <label htmlFor='side'>Side</label>
                                <AsyncPaginate
                                  {...field}
                                  inputId='side'
                                  ref={ref}
                                  isClearable
                                  placeholder={'Your information'}
                                  className={`react-select ${
                                    errors['side'] ? 'error' : ''
                                  }`}
                                  loadOptions={(search, prevOptions) =>
                                    loadOptions(
                                      search,
                                      prevOptions,
                                      sideOptions
                                    )
                                  }
                                />
                              </>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name='side'
                            as={InputError}
                          />
                        </FormItem>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <FormItem>
            <Controller
              name='category'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field: { onChange, onBlur, value} }, ref) => (
                <>
                  <label htmlFor='category'>Category</label>
                  <Select
                    onChange={(v) => {
                      resetField('subCategory')
                      onChange(v)
                    }}
                    onBlur={onBlur}
                    value={value}
                    inputId='category'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${errors['category'] ? 'error' : ''}`}
                    options={categoryOptions}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='informationCategory'
              as={InputError}
            />
          </FormItem>
          {subCategoryOptions.length ? (
            <FormItem>
              <Controller
                name='subCategory'
                control={control}
                rules={{ required: 'This is required' }}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='subCategory'>Sub Category</label>
                    <Select
                      {...field}
                      inputId='subCategory'
                      ref={ref}
                      isClearable
                      placeholder={'Your information'}
                      className={`react-select ${errors['subCategory'] ? 'error' : ''}`}
                      options={subCategoryOptions}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name='subCategory'
                as={InputError}
              />
            </FormItem>
          ) : null}
          <Fieldset>
            <FormItem small>
              <Input
                id='from'
                name='from'
                type='date'
                max={maxDate}
                {...register('from', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='From'
                error={errors.name}
                className={`${errors['from'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='from' as={InputError}/>
            </FormItem>
            <FormItem small>
              <Input
                id='until'
                name='until'
                type='date'
                max={maxDate}
                {...register('until')}
                placeholder='Enter Here'
                label='Until'
                error={errors.name}
                className={`${errors['until'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='until' as={InputError}/>
            </FormItem>
          </Fieldset>
          <FormItem>
            <Input
              id='notes'
              name='notes'
              type='text'
              variant='textarea'
              {...register('notes', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Overview'
              error={errors.name}
              className={`${errors['notes'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='notes' as={InputError}/>
          </FormItem>
          <FormItem>
            <Input
              id='photos'
              name='photos'
              type='file'
              multiple
              accept='image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              {...register('photos', {})}
              placeholder='Enter Here'
              label='Photos'
              error={errors.name}
              className={`${errors['photos'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='photos' as={InputError}/>
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return (
                  <div key={i + index}>
                    {i.name}
                    <Button
                      aria-label='Delete'
                      icon
                      type='button'
                      onClick={() => onDelete(index)}
                    >
                      <Icon name='remove'/>
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
          </FormItem>
          <FormItem>
            <label>Problem Status</label>
            <Controller
              control={control}
              rules={{ required: { value: true, message: 'This is required' } }}
              render={({ field: { onChange, onBlur } }) => (
                <CheckboxGroup>
                  <Checkbox
                    name='major'
                    type='radio'
                    value={1}
                    label='Minor'
                    className={`${errors['major'] ? 'error' : ''}`}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  <Checkbox
                    name='major'
                    type='radio'
                    value={2}
                    label='Major'
                    className={`${errors['major'] ? 'error' : ''}`}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </CheckboxGroup>
              )}
              name={'major'}
            />
            {errors['major'] && <ErrorMessage errors={errors} name='major' as={InputError}/>}
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit Problem
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this problem?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoadingButton}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
