import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from '../../utils/cookie';
import { USER_COOKIE } from '../../utils/constants';
import PublicLayout from "../layouts/PublicLayout";

const PublicRoute = ({ layout: Layout = PublicLayout, children, path, exact = false }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => {
      const isAuthenticated = getCookie(USER_COOKIE);
      return isAuthenticated ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      ) : (
        <Layout>{children}</Layout>
      );
    }}
  />
);

export default PublicRoute;
