import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Redirect,
} from 'react-router-dom';

import { Typography } from '../../atoms/Typography';
import {
  ContentWrapper,
  Fieldset,
  FormItem,
  InputError,
  TableButtonGroup,
  TableHeader,
  TableSubheader,
} from '../../organisms/Layout';
import { Button } from '../../atoms/Button';
import { TabItem } from '../../atoms/TabItem';
import { TabList } from '../../atoms/TabList';
import { InterButton } from '../../atoms/InterButton';
import { Icon } from '../../atoms/Icon';
import { SubheaderWrapper } from '../../organisms/Layout';
import { PlayerInformation } from '../PlayerInformation';
import { PlayerProblems } from '../PlayerProblems';
import { useQuery } from 'react-query';
import { getPlayer } from 'api/players';
import Modal from 'react-modal';
import { Input } from 'components/atoms/Input';
import { useSendNotification } from './hooks/useSendNotification';
import { PdfDocument1 } from './exportPDF';
import { trimFormData } from "../../../utils/trimFormData";
import { PlayerNotes } from "../PlayerNotes";
import { PlayerFiles } from "../PlayerFiles";
import { PlayerReferrals } from "../PlayerReferrals";

Modal.setAppElement('#root');

export const Player = ({ teamId }) => {
  let { playerId } = useParams();

  let { url } = useRouteMatch();

  const { push } = useHistory();

  const params = {
    teamId,
    playerId,
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "Player" for a particular Team by invoking "getTeam" hook
  const { data: playerData, isLoading, isError } = useQuery(
    ['player', playerId],
    () => getPlayer(teamId, playerId)
  );

  const { mutateAsync, isLoading: isUpdating } = useSendNotification();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    clearErrors();
    setIsOpen(!isOpen);
    // reset();
  };

  const [submitMessage, setSubmitMessage] = useState();

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    try {
      const userSelectedDate = new Date(data.date);
      await mutateAsync({
        playerId,
        teamId,
        dueDate: userSelectedDate.toString(),
        msg: data.description,
        title: data.title,
      });
      toggleModalClear();
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      setSubmitMessage(error?.data?.message);
    }
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <>
      {isLoading ? (
        <p>Player information is loading...</p>
      ) : (
        <>
          <ContentWrapper>
            <TableHeader>
              <Typography as={'h1'} tag={'h1'}>
                {playerData?.user?.first_name} {playerData?.user?.last_name}
              </Typography>
              <TableButtonGroup>
                <Button variant={'secondary'} onClick={toggleModal}>
                  Send Notification
                </Button>
                <PdfDocument1 params={params} />
                <Button as={Link} to={`${url}/edit`}>
                  Edit Player
                </Button>
              </TableButtonGroup>
            </TableHeader>
            <SubheaderWrapper>
              <TableSubheader>
                <TabList>
                  <li>
                    <TabItem to={`${url}/problems`}>Problems</TabItem>
                  </li>
                  <li>
                    <TabItem to={`${url}/information`}>App Info</TabItem>
                  </li>
                  <li>
                    <TabItem to={`${url}/notes`}>Rehab Notes</TabItem>
                  </li>
                  <li>
                    <TabItem to={`${url}/files`}>Files</TabItem>
                  </li>
                  <li>
                    <TabItem to={`${url}/referrals`}>Referrals</TabItem>
                  </li>
                </TabList>
              </TableSubheader>
              {isError && (
                  <p>An error occurred during receiving player data</p>
              )}
              <Switch>
                {/* This redirects to the "problems" tab by default */}
                <Redirect exact from={`${url}`} to={`${url}/problems`} />
                <Route path={`${url}/information`}>
                  <InterButton title={'Add information'} as={Link} to={`${url}/add-information`}>
                    <Icon name='add' />
                    Add Info
                  </InterButton>
                  <PlayerInformation params={params} />
                </Route>
                <Route path={`${url}/problems`}>
                  <InterButton title={'Add a problem'} as={Link} to={`${url}/add-problem`}>
                    <Icon name='add' />
                    Add Problem
                  </InterButton>
                  <PlayerProblems params={params} />
                </Route>
                <Route path={`${url}/notes`}>
                  <InterButton title={'Add a Rehab Note'} as={Link} to={`${url}/add-note`}>
                    <Icon name='add' />
                    Add Rehab Note
                  </InterButton>
                  <PlayerNotes params={params} />
                </Route>
                <Route path={`${url}/files`}>
                  <PlayerFiles params={params} />
                </Route>
                <Route path={`${url}/referrals`}>
                  <PlayerReferrals params={params} />
                </Route>
              </Switch>
            </SubheaderWrapper>
          </ContentWrapper>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Send notification
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Fill out the form below to send this player a notification
                  </Typography>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ paddingTop: '14px' }}
                >
                  <FormItem>
                    <Input
                      id='title'
                      name='title'
                      type='text'
                      variant='textarea'
                      maxLength='255'
                      {...register('title', {
                        required: 'This is required',
                      })}
                      placeholder='Enter Here'
                      label='Title'
                      error={errors.name}
                      className={`${errors['title'] ? 'error' : ''}`}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='title'
                      as={InputError}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      id='description'
                      name='description'
                      type='text'
                      variant='textarea'
                      maxLength='255'
                      rows='7'
                      {...register('description', {
                        required: 'This is required',
                      })}
                      placeholder='Enter Here'
                      label='Message (Max 255 characters)'
                      error={errors.name}
                      className={`${errors['description'] ? 'error' : ''}`}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='description'
                      as={InputError}
                    />
                  </FormItem>
                  <Fieldset>
                    <FormItem>
                      <Input
                        id='date'
                        name='date'
                        type='datetime-local'
                        {...register('date', {
                          required: 'This is required',
                        })}
                        placeholder='Enter Here'
                        label='Date'
                        error={errors.name}
                        className={`${errors['date'] ? 'error' : ''}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='date'
                        as={InputError}
                      />
                    </FormItem>
                  </Fieldset>
                  <div className='modal-buttons'>
                    <Button
                      type='button'
                      onClick={toggleModal}
                      variant={'secondary'}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' disabled={isUpdating}>
                      Send Notification
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
};
